<template>
  <div :class="type">
    <shared-user :type="type" :titles="titles" :staff-type="staffType" />
  </div>
</template>

<script>
import SharedUser from '@/components/user/index'

export default {
  name: 'Users',
  components: {
    SharedUser
  },
  data() {
    return {
      type: 'user',
      staffType: 'user',
      titles: [{ label: 'Danh sách người dùng', value: 'user' }]
    }
  }
}
</script>

<style lang="scss" scoped></style>
